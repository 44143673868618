<template>
  <div class="home-contact">
    <h2><span class="text--black">Super Kontakt</span></h2>
    <div class="home-contact-address-container">
      <v-row>
        <v-col cols="12" sm="6">
          <div>
            <strong>SuperMobility</strong> <br/>
            Zellerberg 2-888 <br/>
            6330 Kufstein - Tyrol- Planet Erde <br/>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div>
            Mail: <br/>
            <a href="mailto:info@supermobility.at">info@supermobility.at</a>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-row>
        <v-col class="pb-0 pt-0" cols="12" sm="6">
          <v-text-field
              v-model="contact.todo" solo flat
              label="Dein Name*" clearable
              class="user"
          ></v-text-field>
          <v-text-field
              v-model="contact.name"
              solo :rules="rules" flat
              label="Dein Name*"
              clearable required
          ></v-text-field>
          <v-text-field
              v-model="contact.companyName"
              solo :rules="rules" flat
              label="Name des Betriebes*"
              clearable required
          ></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0" cols="12" sm="6">
          <v-text-field
              v-model="contact.eMail"
              solo :rules="emailRules" flat
              label="E-Mail Adresse*"
              clearable required
          ></v-text-field>
          <v-text-field
              v-model="contact.telephone"
              solo flat
              label="Telefon"
              clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-textarea solo clearable required :rules="rules" flat color="black"
                      placeholder="Gemeinsam schaffen wir weniger Fahrzeuge mehr zu bewegen! So helfen wir unsere Erde mehr zu schützen :-)."
                      v-model="contact.message">
          </v-textarea>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-checkbox v-model="contact.privacy" class="checkbox" reqired :rules="rules" color="#EAF2F6">
            <template v-slot:label>
              <div class="checkbox-label">
                Ich habe die <a @click.stop.prevent="goTo('Datenschutz')">Datenschutzerklärung</a>
                gelesen, verstanden und akzeptiert.
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" class="text-center">
          <div>
            <v-btn @click="submit" :disabled="loading" :loading="loading" outlined>
              Super senden <v-icon>{{icons.right}}</v-icon>
            </v-btn>
          </div>
          <div class="mt-4" v-if="response.success">
            <v-alert dense type="success" style="display: inline-block;">
              Die Anfrage wurde erfolgreich versendet!
            </v-alert>
          </div>
          <div class="mt-4" v-if="response.error">
            <v-alert dense type="error" style="display: inline-block;">
              Die Anfrage konnte nicht versendet werden!
            </v-alert>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js';

export default {
  name: 'Contact',
  data: () => ({
    icons: {
      right: mdiChevronRight
    },
    contact: {
      todo: null,
      name: null,
      eMail: null,
      companyName: null,
      telephone: null,
      message: null,
      privacy: false,
    },
    valid: undefined,
    loading: false,
    response: {
      error: false,
      success: false
    },
    rules: [
      v => !!v || 'Pflichtfeld!'
    ],
    emailRules: [
      v => !!v || 'Pflichtfeld!',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-Mail Adresse ist ungültig!'
    ],
  }),
  methods: {
    goTo(pathName) {
      this.$router.push({ name: pathName });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.response.error = false;
        this.loading = true;
        this.$refs.recaptcha.execute();
      } else {
        this.response.error = true;
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        if (this.contact.todo === null) {
          const request = {
            contact: {
              name: this.contact.name,
              eMail: this.contact.eMail,
              companyName: this.contact.companyName,
              telephone: this.contact.telephone,
              message: this.contact.message,
              privacy: this.contact.privacy
            }
          };
          this.$http.post('contact', request).then(
              () => {
                this.loading = false;
                this.response.error = false;
                this.response.success = true;
                this.$refs.form.reset();
              },
              () => {
                this.loading = false;
                this.response.success = false;
                this.response.error = true;
              },
          );
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/variables.scss";
  @import "../../assets/scss/layout.scss";
  .home-contact {
    z-index: 1;
    position: relative;
    margin-top: 100px;
    padding-left: 200px;
    padding-right: 200px;
    @include MQ(M) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @include MQ(S) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @include MQ(XS) {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 50px;
    }
    .user {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
    }
    h2 {
      font-weight: 400;
      font-size: 35px;
      line-height: 38px;
      margin-bottom: 50px;
      .text--black {
        color: black;
        font-weight: 700;
      }
      @include MQ(M) {
        font-size: 28px;
        line-height: 31px;
      }
      @include MQ(XS) {
        font-size: 30px;
        line-height: 33px;
        margin-bottom: 40px;
      }
    }
    .home-contact-address-container {
      border: 3px solid #EBF3F6;
      border-radius: 15px;
      margin-bottom: 60px;
      color: #444444;
      opacity: 0.5;
      font-size: 15px;
      padding: 20px;
      font-weight: 400 !important;
      a {
        color: #444444;
        font-size: 15px;
        text-decoration: none;
      }
      strong {
        font-weight: 700 !important;
      }
      .col-12 {
        display: flex;
        align-items: center;
        justify-content: center;
        @include MQ(XS) {
          align-items: flex-start;
          justify-content: left;
        }
      }
    }
    .v-form {
      position: relative;
    }
    ::v-deep .v-text-field--solo > .v-input__control > .v-input__slot {
      background: #EAF2F6 !important;
    }
    ::v-deep .v-input {
      border-radius: 15px;
      .v-label {
        color: #444444 !important;
      }
      &.error--text {
        .v-label {
          color: #ff5252 !important;
        }
        textarea {
          &::placeholder {
            color: #ff5252 !important;
          }
        }
      }
    }
    ::v-deep textarea {
      &::placeholder {
        color: #444444 !important;
      }
    }
    ::v-deep .checkbox {
      margin-top: 0;
      .checkbox-label {
        color: black;
        a {
          color: black;
          text-decoration: underline;
        }
      }
      .v-icon {
        color: #EAF2F6 !important;
        caret-color: #EAF2F6 !important;
      }
      &.error--text {
        .checkbox-label {
          color: #ff5252;
          a {
            color: #ff5252;
          }
        }
      }
      &.v-input--is-label-active {
        .v-icon {
          color: $primary !important;
          caret-color: $primary !important;
        }
      }
    }
    ::v-deep .v-btn {
      color: #444444 !important;
      border-radius: 5px;
      font-size: 18px;
      height: auto !important;
      font-weight: 300;
      text-transform: none;
      border-width: 2px;
      .v-btn__content {
        padding: 10px 5px;
      }
    }
    ::v-deep .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
      background-color: black !important;
      color: white !important;
    }
    #contact_form {
      display: inline-block;
      position: absolute;
      left: 0;
      @include MQ(XS) {
        position: relative;
      }
      @include MQ(S) {
        position: relative;
      }
    }
  }
</style>
